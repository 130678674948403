import './App.css';
import Paradise from './components/Paradise';
import Minter from './components/Minter';
// import Minter2 from './components/Minter2';
import BallerBears from './components/BallerBears';
import AlienFrens from './components/AlienFrens';
import Doadz from './components/Doadz';
import Melties from './components/Melties';
import BlazedPunks from './components/BlazedPunks';
import ReactGA from 'react-ga4';
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  ReactGA.initialize('G-GPCHQL0M74');
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Minter />} />
          {/* <Route path="/minter2" element={<Minter2 />} /> */}
          <Route path="/paradise" element={<Paradise />} />
          <Route path="/ballerbears" element={<BallerBears />} />
          <Route path="/alienfrens" element={<AlienFrens />} />
          <Route path="/doadz" element={<Doadz />} />
          <Route path="/melties" element={<Melties />} />
          <Route path="/blazedpunks" element={<BlazedPunks />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
