import ReactGA from 'react-ga4';

const Web3 = require('web3');

const contractABI = require('../contract-abi.json')
const contractAddress = "0xfa4b2b8178178ebef8540056e6e1651eadbc7577";

export const connectWallet = async () => {
  ReactGA.event({
    category: 'User',
    action: 'Connect Wallet Attempt'
  });
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "👆🏽 Enter the number of iNFanTs you would like to mint.",
        address: addressArray[0],
      };
      ReactGA.event({
        category: 'User',
        action: 'Connect Wallet Success'
      });
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Enter the number of iNFanTs you would like to mint.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const mintInfant = async(count) => {
  // error handling
  if(!window.ethereum || !window.ethereum.selectedAddress) {
    return {
      success: false,
      status: "❗Please connect your wallet before minting."
    }
  }
  if (!Array.from({length: 20}, (_, i) => (i + 1).toString()).includes(count)) { 
    return {
      success: false,
      status: "❗Enter a number between 1 and 20, inclusive.",
    }
  }

  var web3 = new Web3(window);

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  //set up your Ethereum transaction
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    value: web3.utils.toHex(web3.utils.toWei(('0.01' * count).toString(), 'ether')),
    'data': window.contract.methods.mintInfant(count).encodeABI() //make call to NFT smart contract 
  };


  ReactGA.event({
    category: 'User',
    action: 'Mint Attempt'
  });

  //sign the transaction via Metamask
  try {
    const txHash = await window.ethereum
      .request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
      });
    ReactGA.event({
      category: 'User',
      action: 'Mint Success'
    });
    return {
      success: true,
      status: "✅ Robots lovingly delivered by robohash.org. Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" + txHash
    }
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message
    }
  }
}
