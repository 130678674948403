import { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected, mintInfant } from "../utils/interact.js";
import ReactGA from 'react-ga4';

const RopstenDetails = () => {
  return (
    <div>
    <p>As this is for demonstration purposes, it runs on Ropsten (not mainnet).</p>
    <div>Verified Smart Contract: <a href={"https://ropsten.etherscan.io/address/0x045c0d8ba345fed4709aad90d620c82e5a765c13#code"}>etherscan link</a></div>
    <p>If you need Ropsten Ξ, try one of the following faucets.</p>
    <ol>
      <li>
        <a href="https://www.moonborrow.com">moonborrow.com</a>
        <div>This is the most reliable source. It is run by Keir Finlow-Bates.</div>
        <ul>
          <li><a href="https://twitter.com/bcgandalf/status/1390603270763294720">Twitter post</a></li>
          <li><a href="https://linkedin.com/in/keirf">LinkedIn</a></li>
          <li><a href="https://www.linkedin.com/pulse/how-mine-ropsten-testnet-ether-keir-finlow-bates">LinkedIn post (see comments)</a></li>
        </ul>
      </li>
      <li>
        <a href="https://faucet.ropsten.be">faucet.ropsten.be</a>
      </li>
      <li>
        <a href="https://faucet.dimensions.network">faucet.dimensions.network</a>
      </li>
      <li>
        <a href="https://faucet.metamask.io">faucet.metamask.io</a>
      </li>
    </ol>  
    </div>
  )
}

const RinkebyDetails = () => {
  return (
    <div>
    <p>As this is for demonstration purposes, it runs on Rinkeby (not mainnet).</p>
    <div>Verified Smart Contract: <a href={"https://rinkeby.etherscan.io/address/0xfa4b2b8178178ebef8540056e6e1651eadbc7577#code"}>etherscan link</a></div>
    <div>Collection: <a href={"https://testnets.opensea.io/collection/infant"}>opensea link</a></div>
    <p>If you need Rinkeby ETH, try one of the following faucets. It's free to use!</p>
    <ol>
      <li>
        <a href="https://faucets.chain.link/rinkeby">faucets.chain.link/rinkeby</a>
      </li>
      <li>
        <a href="https://faucet.rinkeby.io/">faucet.rinkeby.io</a>
      </li>
    </ol>  
    </div>
  )
}

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [count, setCount] = useState("");
 
  useEffect(async () => {
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);

    addWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
      const { status } = await mintInfant(count);
      setStatus(status);  
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Enter the number of iNFanTs you would like to mint.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (

    <div className="Minter">

      {/* <div>Looking for the Paradise rarity calculation? <a href={"/paradise"} onClick={ReactGA.event({ category: 'User', action: 'Rarity Check Click' })}>It's moved!</a></div>
      <br/> */}

      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>

      <br></br>
      <h1 id="title">iNFanT mint!</h1>
      
      <RinkebyDetails/>

      <form>
        <h2>How many?</h2>
        <input
          type="text"
          placeholder="specify a number from 1-20"
          onChange={(event) => setCount(event.target.value)}
        />
      </form>
      <button id="mintButton" onClick={onMintPressed}>
        Mint iNFanT
      </button>
      <p id="status">
        {status}
      </p>
    </div>
  );
};

export default Minter;
